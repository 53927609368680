<template>
  <location-lists-editor-base 
    :applicationId='applicationId'
    :locationListId='locationListId'
    :locationId="locationId"
    @onEditLocation="editLocationList"
    @onBackToParent="backToParent"
  />
</template>

<script>

import LocationListsEditorBase from './LocationListsEditorBase.vue'

export default {
  components: {
    LocationListsEditorBase
  },
  props: {
    applicationId: { required: true },
    locationListId: { required: true },
    locationId: { required: true },
  },
  methods: {
    // event subscriber
    editLocationList(locationId){
      this.$router.push({
        name: 'application-tools-location-editor-level3',
        params: { 
          applicationId: this.$route.params.applicationId,
          locationListId: this.locationListId,
          locationId: locationId,
        }
      })
    },
    // event subscriber
    backToParent (parentLocationId) {
      this.$router.push({
        name: 'application-tools-location-editor-level1',
        params: { 
          applicationId: this.$route.params.applicationId,
          locationListId: this.locationListId,
          locationId: parentLocationId,
        }
      })
    },
  }
}
</script>